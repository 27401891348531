body {
  background-color: #FFF;
}

.text-success-light {
  color: #0ba673
}

.middle-dot {
  font-weight: bold;
  font-size: 24px;
  position: relative;
  color: #0ba673;
  /* margin-top: -15px; */
}

.doc-about p {
  color: #444;
}

.text-justify {
  text-align: justify;
}

/*** LANDING PAGE ***/
.muted-background-image {
  background-image: linear-gradient(45deg, #919b9d, #cde0e7, #919b9d);
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.quote {
  border-top: 1px solid #cde0e7;
  border-bottom: 1px solid #cde0e7;
}

.youtube-screen-hover:hover, .another-theme-block:hover {
  box-shadow: 0px 0px 10px #BCBCBC;
}

.another-theme-block .another-theme-block-annotation {
  min-height: 6em;
}

.logo-header .logo {
  padding-top: 2em;
  padding-bottom: 2em
}

.landing-start {
  position: relative;
}

.landing {
  color: #444;
}

.landing .logo img {
  width: 250px;
}

.landing .title {
  font-size: 2em;
}

.modal-dialog {
  max-width: 96%;
}

@media (min-width: 460px) {
  .landing .title {
    font-size: 4em;
  }
}

@media (min-width: 992px) {
  .landing .portrait {
    margin-top: -85px;
    max-width: 324px;
  }

  .landing .title {
    font-size: 4em;
  }

  .landing .description {
    font-size: 2em;
  }
}

@media (min-width: 1200px) {
  .modal-dialog {
    max-width: 1140px;
  }

  .landing .portrait {
    margin-top: -95px;
    max-width: 380px;
  }

  .landing .title {
    font-size: 5em;
  }

  .landing .description {
    font-size: 2.5em;
  }

  .h1 {
    font-size: 3em;
  }
}

@media (min-width: 1400px) {
  .landing .portrait {
    margin-top: -105px;
    max-width: 440px;
  }

  .landing .title {
    font-size: 6em;
  }

  .landing .description {
    font-size: 3em;
  }
}

.landing .title {
  color: #006592;
  line-height: 1.3em;
  font-weight: 300;
}

.landing .description {
  color: #006592;
  font-weight: 300;
}

/* .landing .landing-cost {
  color: #D00;
  font-size: 5em;
  font-weight: 300;
} */
.border-red {
  border: 2px solid #D00;
}

.landing .portrait {
  position: absolute;
  padding: 1.2em;
  background: #FFF;
}

.landing .portrait, .portrait img,
.doc-card-photo img, .doc-card-photo .banner-about {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.landing .form-header {
  /* font-size: 2em; */
  color: #006592;
}

.consult-form {
  max-width: 400px;
}

.landing .protection {
  display: flex;
  padding-left: 8px;
  -webkit-align-items: center;
  align-items: center;
}

.landing .protection .fal {
  font-size: 32px;
}

.landing .protection p {
  margin-top: 15px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 13px;
  text-align: left;
  /* color: #c4c4c4; */
}

.landing .btn-landing-primary {
  background-color: #006592;
}

.landing .doc-fio {
  color: #006592;
  /* font-size: 3em; */
  font-weight: 300;
}

.landing .doc-spec-title {
  color: #006592;
  /* font-size: 1.7em; */
}

/* .landing .doc-spec {} */
.landing .quote {
  text-indent: 2em;
  /* font-size: 1.3em; */
}

.landing .advantages ul li {
  margin-bottom: 1em;
}

.landing .advantages {
  /* font-size: 1.3em; */
}

.read-more-advantages {
  color: #006592;
}

.reviews .review-name {
  font-weight: bold;
  /* font-size: 1.2em; */
}

.reviews .review-city {
  font-weight: bold;
  color: #006592;
  /* font-size: 1em; */
  /* float: right; */
  text-align: right;
}

.reviews .review-stars {
  color: #0ba673;
}

.reviews .review {
  text-align: justify;
}

/* .one-review{} */