body {
  background-color: #F5F9Fd;
}

header {
  font-family: 'Montserrat', sans-serif;
}

header {
  font-family: 'Montserrat', sans-serif;
}

/*** SHOP ***/
.specline {
  background-color: #a0fd9d;
}

.btn-dark i {
  color: #FFF;
}

.btn-primary-gradient {
  color: #FFF !important;
  background: linear-gradient(0.25turn, #006592, #45B4E6);
  border-color: #45B4E6;
}

.btn-dark-gradient {
  color: #FFF !important;
  background: linear-gradient(0.25turn, #1D2B37, #5E6A74);
  border-color: #5E6A74;
}

.btn-light-gradient {
  background: linear-gradient(0.25turn, #FFF, #FFF);
  border-color: #F2F6FA;
}

.btn-success-gradient {
  background: linear-gradient(0.25turn, #30A22C, #6CF867);
  border-color: #30A22C;
}

.backpage li, .backpage li a {
  color: #707070;
}

.breadcrumbs li, .breadcrumbs li a, .breadcrumb li, .breadcrumb li a {
  color: #707070;
  text-decoration: none;
}

.breadcrumb-item {
  font-size: 15px;
}

.breadcrumb-item.active {
  font-weight: bold;
}

.logo-menu .dropdown-toggle {
  font-size: 12px;
  font-weight: bold;
  background: #F5F9Fd 0% 0% no-repeat padding-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding-right: 30px;
}

.logo-menu .dropdown-toggle::after {
  /* font-family: "Font Awesome 5 Pro"; */
  border: none;
  content: "";
}

.logo-menu-second li a {
  font-weight: bold;
}

.logo-menu-second li a, .logo-menu-second .navbar-text,
.logo-menu li a, .logo-menu .navbar-text,
.top-menu li a, .top-menu .navbar-text,
.specline {
  font-size: 12px;
}

.thanks-page .icon .fal {
  font-size: 10em;
  color: #006592;
}

.category-banner .card {
  height: 200px;
  overflow: hidden;
  /*
  display: flex;
  align-items: center;
  flex-wrap: wrap; */
}

.category-banner img {
  /* margin-top: -25%; */
  /* vertical-align: middle; */
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* .category-banner img {
  min-height: 200px;
} */
.image-categories-big a {
  color: #FFF;
  text-decoration: none;
  /* margin-bottom: 0; */
}

.image-categories-big .card .card-text {
  font-size: 15px;
}

.image-categories-big .card .card-title {
  font-size: 21px;
  font-weight: bolder;
}

.banner-about {
  background: url('../../public/build/images/shop/datktransparentbg.png');
}

.image-categories-big .card .card-title .fa-arrow-right {
  font-size: 12px;
  margin-top: 12px;
}

.image-categories-big-footer .card-img-overlay {
  width: 200px;
}

.category-seo-text a {
  color: #707070;
}

.footer {
  background: linear-gradient(0.25turn, #146f9a, #43aad8);
  color: #FFF;
}

.footer .border-bottom {
  border-bottom: 1px solid #fff;
}

.social {
  background: #3ea5d3;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
}

.image-categories-small .card-title {
  min-height: 3em;
}

.image-categories-small .card, .tovars a {
  color: #1D2B37;
  text-decoration: none;
}

.image-categories-small .card, .tovars .card {
  text-align: center;
  box-shadow: 0px 3px 6px #ddd;
  border: none;
  cursor: pointer;
}

.sidebar {
  background: #FFF;
  box-shadow: 0px 3px 6px #ccc;
}

.sidebar a {
  color: #1D2B37;
}

.image-categories-small .card:hover, .tovars .card:hover {
  box-shadow: 0px 3px 6px #ccc;
}

.image-categories-small .card-img-offset img {
  max-height: 100px;
}

.tovars .card-img-offset {
  min-height: 150px;
}

.tovars .card-title {
  min-height: 3em;
}

.tovars .card-img-offset img {
  max-height: 150px;
}

/* .image-categories-small .card-img-offset {
  height: 100px;
} */
.image-categories-small a {
  color: #000;
  text-decoration: none;
}

.image-categories-small .card-title, .tovars .card-title {
  font-size: 12px;
  font-weight: bold;
}

.image-categories-small .card-text, .tovars .card-text {
  font-size: 12px;
}

.tovars .card-title, .tovars .card-text {
  text-align: left;
}

.tovars small {
  font-size: 12px;
}

.sort-count .btn {
  color: #1D2B37;
  font-size: 12px;
}

.sort-count ul li {
  color: #1D2B37;
  font-size: 12px;
}

.cost {
  font-size: 17px;
  font-weight: bold;
  color: #D00;
}

.old-cost {
  font-size: 12px;
  font-weight: bold;
}

.pagination .page-item .page-link {
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  padding: 0;
  color: #1D2B37;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.pagination .page-item.ellipsis .page-link {
  border: none;
  background-color: #F5F9Fd;
}

.pagination .page-item.ellipsis:hover .page-link {
  border: none;
  background-color: #F5F9Fd;
}

.pagination .active {
  color: #FFF !important;
  background: linear-gradient(164deg, #006592, #45B4E6);
  border-color: #45B4E6;
}

.one-tovar .check-circle {
  background: #9DFF99;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  display: inline-block;
}

.one-tovar .is-in-stock p {
  font-size: 15px;
  display: inline-block;
}

.one-tovar .fa-check {
  color: #1D2B37
}

.one-tovar .annotation p {
  font-size: 16px;
}

.one-tovar .add-to-basket-widget {
  border: 1px solid #E8E8E8;
}

.one-tovar .add-to-basket-widget .form-control {
  padding: 0px;
}

.one-tovar .terms-of-order {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2em;
}

.one-tovar .btn-to-basket {
  height: 33px;
}

/* .one-tovar .to-order {
  min-width: 180px;
} */
.one-tovar .cntbtn {
  background: #F6F6F6;
  font-size: 12px;
}

/* .one-tovar .nav-tabs .nav-item {paddin} */
.one-tovar .nav-tabs .nav-item .nav-link {
  background: #E3E9EF;
  border: 1px solid #E8E8E8;
  border-bottom: none;
  color: #1D2B37;
}

.one-tovar .nav-tabs .nav-item .active {
  color: #FFF !important;
  background: linear-gradient(0.25turn, #006592, #45B4E6);
  border-color: #45B4E6;
}

.one-tovar .tab-content {
  background: #FFF;
  min-height: 300px;
  border-top: 6px solid #006592;
}

.basket-row {
  background: #FFF;
}

.basket-row {
  /* display: table; */
  text-align: center;
  border-bottom: 1px solid #E2E9F1;
}

.basket-row .col {
  /* display: table-cell; */
}

.basket-row-tovars .col {
  /* outline: 1px solid #E8E8E8; */
}

.basket-row-tovars .photo {
  border-right: none;
}

.basket-row-tovars .tovar {
  border-left: none;
}

.basket-row .photo img {
  max-height: 70px;
}

.basket-row .tovar {
  text-align: left;
}

.basket-row .tovar .title, .basket-row .tovar p {
  font-size: 14px;
}

.basket-row .tovar .title {
  font-weight: bold;
}

.basket-row .count .input-group {
  margin: 0 auto;
  width: 140px;
}

.basket-row .basket-cost {
  color: #006592;
  font-size: 21px;
  font-weight: bold;
  padding: 1em 0 1em 0;
}

.basket-row .basket-count {
  font-size: 21px;
  font-weight: bold;
  padding: 1em 0 1em 0;
}

.basket-row .change-tovar-basket {
  font-size: 16px;
  color: #363636;
  text-decoration: none;
}

.basket-row .remove-tovar-basket {
  font-size: 16px;
  color: #E71726;
  text-decoration: none;
}

.basket-row .th-dark {
  background: #1A1818;
  color: #FFF;
}

.basket-row .th-darkest {
  background: #2B2B2B;
  color: #FFF;
}

.row .order-price-cost {
  font-size: 34px;
  color: #434C54;
  font-weight: bold;
}

.row .order-price-text {
  font-size: 22px;
  color: #434C54;
}

fieldset.for-design {
  background: #FFF;
  padding: 2em 1.5em 2em 1.5em;
}

legend.for-design {
  padding: .5em 2em .5em 2em;
  width: auto;
  float: none;
  margin: 0 auto;
  color: #1A1818;
  font-size: 15px;
  background: #E2E9F1;
}

.color-1A1818 {
  color: #1A1818;
}

input.form-control, textarea.form-control {
  border: 2px solid #006592;
  font-size: 15px;
  padding: .6em;
}

input.form-control::placeholder, textarea.form-control::placeholder {
  /* color: #1A1818; */
}

/* fieldset.for-design .form-check-label p{font-weigh} */
/* CAROUSEL*/
.carousel-tovar {
  display: block;
  text-align: center;
  background: #FFF;
}

.carousel-tovar img {
  height: 150px;
}

.carousel-item.carousel-item-start~.carousel-item {
  /* , .carousel-item.carousel-item-end~.carousel-item */
  /* .carousel-item.carousel-item-next~.carousel-item, .carousel-item.carousel-item-prev~.carousel-item { */
  /* display: none; */
  /* background: #FFF; */
}

.carousel-item-end {
  /* , .carousel-item.carousel-item-end~.carousel-item */
  /* .carousel-item.carousel-item-next~.carousel-item, .carousel-item.carousel-item-prev~.carousel-item { */
  /* display: none; */
  /* background: #FFF; */
}

/* , .carousel-item-prev  */
.carousel-item-next {
  margin-left: -65px;
}

.carousel-item-prev~.carousel-item-end {
  margin-left: -65px;
}

.carousel-item-next .carousel-tovar, .carousel-item-prev .carousel-tovar {
  visibility: hidden;
}

.carousel-item-start .carousel-tovar:last-child {
  visibility: visible;
}

.carousel-item-end .carousel-tovar:first-child {
  visibility: visible;
}

.carousel .fa-chevron-right, .carousel .fa-chevron-left {
  font-size: 24px;
  color: #1A1818;
}

@media (max-width: 767px) {
  .carousel-inner .carousel-item>div {
    display: none;
  }

  .carousel-inner .carousel-item>div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

  .carousel-inner .carousel-item-end.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(25%);
  }

  .carousel-inner .carousel-item-start.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-25%);
  }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

@media (min-width: 768px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}